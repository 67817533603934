

.fancy-font{
  font-family: 'Josefin Slab', serif;
  font-weight:500;
}
body{
  background: #342D52;
  background: radial-gradient(64.25% 128.5% at 49.5% 70.25%, #A3BCF9 0%, #342D52 100%)  no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.bg-dark-shade{
  background: rgba(52, 45, 82, .55);
}
.bg-light-shade{
  background: rgba(249, 250, 255, .80);
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
